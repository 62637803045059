import type { InsuranceOffer } from '@backmarket/http-api'

export function filterWithMonthlyExperiment(
  experiment: string,
  offer: InsuranceOffer,
) {
  // We keep the default and the catchup offers
  if (offer.defaultOffer || offer.isCatchUpOffer) return true

  if (experiment === 'monthlyOnly') {
    return offer.isMonthly
  }

  if (experiment === 'upfrontOnly') {
    return !offer.isMonthly
  }

  return true
}
