import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { CHECKOUT } from '~/scopes/checkout/routes-names'
import { useAddressStore } from '~/scopes/checkout/stores/addressStore'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'
import { tryToSaveBouyguesAddress } from '~/scopes/checkout/utils/saveBouyguesAddress'

// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware(async () => {
  const cartStore = useCartStore()
  const { shipping, billing } = useAddressStore()

  if (!cartStore.isCartLoaded) {
    await cartStore.fetchCart()
  }

  if (cartStore.hasBouyguesMobilePlan) {
    const [shippingSaved, billingSaved] = await Promise.all([
      tryToSaveBouyguesAddress(shipping, 'SHIPPING'),
      tryToSaveBouyguesAddress(billing, 'BILLING'),
    ])

    if (!shippingSaved) {
      useLogger().info(
        '[CHECKOUT] [BOUYGUES] User shipping address needs normalization. Redirecting to shipping address step.',
        { owners: ['bot-squad-checkout-front', 'bot-squad-b2cservices-front'] },
      )

      return navigateTo({ name: CHECKOUT.SHIPPING_ADDRESS })
    }

    if (!billingSaved) {
      useLogger().info(
        '[CHECKOUT] [BOUYGUES] User billing address needs normalization. Redirecting to billing address step.',
        { owners: ['bot-squad-checkout-front', 'bot-squad-b2cservices-front'] },
      )

      return navigateTo({ name: CHECKOUT.BILLING_ADDRESS })
    }
  }
})
