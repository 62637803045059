import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { CHECKOUT } from '~/scopes/checkout/routes-names'
import { useAddressStore } from '~/scopes/checkout/stores/addressStore'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'

// As per nuxt documentation we should not return anything or return undefined if we do not want to alter the redirection.
// https://nuxt.com/docs/guide/directory-structure/middleware
// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware(async (to) => {
  const cartStore = useCartStore()
  const addressStore = useAddressStore()

  const logger = useLogger()

  if (to.name === CHECKOUT.ADDRESS_CONFIRMATION) {
    return true
  }

  // We don't want to fire the request each time this middleware is instantiated.
  // If the cart was already fetched, there is no need to fetch it
  // again, and we should use the data saved in our store.
  if (!cartStore.isCartLoaded) {
    await cartStore.fetchCart()
  }

  if (!cartStore.isShippable) {
    logger.info(
      '[CHECKOUT][MIDDLEWARE] Cart is not shippable to the selected Country',
      {
        isCartLoaded: cartStore.isCartLoaded,
        isShippingAddressComplete: addressStore.isShippingAddressComplete,
        shippableCountries: addressStore.shippableCountries,
        shippingCountry: addressStore.shipping.country,
        owners: ['bot-squad-checkout-front'],
      },
    )

    return navigateTo({ name: CHECKOUT.SHIPPING_ADDRESS })
  }
})
