export default {
  page_delivery: {
    id: 'funnel_legal_delivery',
    defaultMessage:
      'This information is processed by Back Market (JUNG SAS) and the sellers to manage your order. You have the right to access, rectify and delete your personal data. More info in our {privacy, html}',
  },
  page_payment: {
    id: 'funnel_legal_payment',
    defaultMessage:
      'Your payment information is processed securely by our payment service providers (PCI-DSS certified). More info in our {privacy, html}',
  },
  link: {
    id: 'funnel_legal_links_privacy_link',
    defaultMessage: 'Data Protection Policy',
  },
} as const
