import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { CHECKOUT } from '~/scopes/checkout/routes-names'
import { useBouyguesStore } from '~/scopes/checkout/stores/bouyguesStore'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'

// As per nuxt documentation we should not return anything or return undefined if we do not want to alter the redirection.
// https://nuxt.com/docs/guide/directory-structure/middleware
// eslint-disable-next-line consistent-return
export async function bouyguesMobilePlanRouteMiddleware() {
  const cartStore = useCartStore()
  const bouyguesStore = useBouyguesStore()

  const hasBouyguesAuthToken = !isEmpty(bouyguesStore.accessToken)
  const hasBouyguesPhonelineInfo = !isEmpty(
    bouyguesStore.phonelineSetupInformation,
  )

  const hasCompleteBouyguesFlow =
    hasBouyguesAuthToken && hasBouyguesPhonelineInfo
  const hasBouyguesMobilePlan = !isEmpty(cartStore.bouyguesMobilePlan)

  if (hasBouyguesMobilePlan && !hasCompleteBouyguesFlow) {
    return navigateTo({
      name: !hasBouyguesAuthToken
        ? CHECKOUT.SERVICES.BOUYGUES.SIGN_UP
        : CHECKOUT.SERVICES.BOUYGUES.PHONELINE_SETUP,
    })
  }
}

export default defineNuxtRouteMiddleware(bouyguesMobilePlanRouteMiddleware)
