<template>
  <div class="flex flex-col gap-32 md:flex-row">
    <div class="space-y-32 md:w-1/2">
      <h1 class="heading-1">
        {{ i18n(translations.title) }}
      </h1>
      <div class="space-y-16">
        <div class="body-1">{{ i18n(translations.notEligibleMessage) }}</div>

        <div class="flex flex-col justify-end gap-16 md:flex-row">
          <RevButton
            full-width="adaptive"
            :to="resolveLocalizedRoute({ name: CHECKOUT.CART })"
            variant="primary"
          >
            {{ i18n(translations.goToCart) }}
          </RevButton>

          <RevButton
            full-width="adaptive"
            :to="resolveLocalizedRoute({ name: HOME })"
            variant="secondary"
          >
            {{ i18n(translations.continueShopping) }}
          </RevButton>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-center md:w-1/2">
      <RevIllustration
        :alt="i18n(translations.emptyBasketIcon)"
        class="py-44"
        :height="223"
        src="/img/bouygues/emptyBasket.svg"
        :width="236"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'
import { onMounted } from 'vue'

import { deleteService } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'

import { CHECKOUT } from '~/scopes/checkout/routes-names'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'
import { HOME } from '~/scopes/home/route-names'

import translations from './NotEligible.translations'

const logger = useLogger()
const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const cartStore = useCartStore()

onMounted(async () => {
  if (cartStore.bouyguesMobilePlan) {
    try {
      await $httpFetch(deleteService, {
        body: { offerId: cartStore.bouyguesMobilePlan.id },
      })
      await cartStore.fetchCart()
    } catch (err) {
      const error = err as Error
      logger.error(error.message, {
        error,
        owners: ['bot-squad-b2cservices-front'],
      })
    }
  }
})
</script>
