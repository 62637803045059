export default {
  title: {
    id: 'checkout_services_bouygues_not_eligible_title',
    defaultMessage: 'Sorry, you’re not eligible for a Bouygues Telecom bundle.',
  },
  notEligibleMessage: {
    id: 'checkout_services_bouygues_not_eligible_message',
    defaultMessage:
      'If you’d like, you can still buy the device outside of the bundle. This won’t include the Bouygues Telecom offer.',
  },
  goToCart: {
    id: 'checkout_services_bouygues_not_eligible_go_to_cart',
    defaultMessage: 'Go to cart',
  },
  continueShopping: {
    id: 'checkout_services_bouygues_not_eligible_continue_shopping',
    defaultMessage: 'Continue shopping',
  },
  emptyBasketIcon: {
    id: 'checkout_services_bouygues_login_form_empty_basket_icon',
    defaultMessage: 'Empty Basket icon',
  },
}
