import {
  type InsuranceOffer,
  type InsuranceOfferWithSelectedState,
  type MonetaryAmount,
} from '@backmarket/http-api'
import { getCart } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import type {
  CartCollectionPoint,
  CartItem,
  CartResponse,
  CartSummary,
  LastCollectionPoint,
  ShippingChoice,
  ShippingOption,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { getProductInfos } from '@backmarket/nuxt-layer-recommendation/helpers.ts'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import type { EventData } from '@backmarket/nuxt-module-tracking'
import { formatPrice } from '@backmarket/nuxt-module-tracking/formatPrice'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { defineStore } from 'pinia'

import { filterWithMonthlyExperiment } from '../pages/Cart/components/CartItemCard/components/Insurances/Insurances.utils'
import { CHECKOUT_STEP_IDS } from '../routes-names'
import { isBouyguesOffer } from '../utils/isBouyguesOffer'

import { useAddressStore } from './addressStore'
import { useDiscountStore } from './discountStore'
import { useSwapStore } from './swapStore'
import { useUserInformationStore } from './userInformationStore'

function getPaymentType(insurance?: InsuranceOffer) {
  if (!insurance || insurance.defaultOffer) return ''

  return insurance.isMonthly ? 'monthly' : 'upfront'
}

interface CartState {
  price: MonetaryAmount
  tax: MonetaryAmount | null
  totalPriceIncludesTax: boolean | null
  serviceFee: MonetaryAmount | null
  totalShippingPrice: MonetaryAmount
  totalPriceWithoutShipping: MonetaryAmount
  totalListingsPrice: MonetaryAmount
  totalPriceAfterBuyback?: MonetaryAmount
  totalGrossPriceWithoutAddonService?: MonetaryAmount
  totalGrossPriceWithoutBmFee?: MonetaryAmount
  items: CartItem[]
  lastCollectionPoint: LastCollectionPoint | Record<string, never>
  showCatchupModal: boolean
  isCartLoaded: boolean
}

export const useCartStore = defineStore('cart', {
  state: (): CartState => ({
    price: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    tax: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalPriceIncludesTax: false,
    serviceFee: null,
    totalShippingPrice: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalPriceWithoutShipping: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalListingsPrice: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalGrossPriceWithoutAddonService: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalGrossPriceWithoutBmFee: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalPriceAfterBuyback: undefined,
    items: [],
    lastCollectionPoint: {},
    showCatchupModal: true,
    isCartLoaded: false,
  }),
  getters: {
    bouyguesMobilePlan: (state) => {
      const itemWithBouyguesOffer = state.items.find((item) => {
        if (!item.mobilePlan) return false

        return isBouyguesOffer(item.mobilePlan.selectedOffer)
      })

      return itemWithBouyguesOffer?.mobilePlan?.selectedOffer
    },
    hasBouyguesMobilePlan(): boolean {
      return !isEmpty(this.bouyguesMobilePlan)
    },
    hasServiceFee: (state) => !isEmpty(state.serviceFee),
    itemsIds: (state) => state.items.map((item) => item.listingId),
    availableItems: (state) =>
      state.items.filter((item) => item.available === 'yes'),
    unavailableItems: (state) =>
      state.items.filter((item) => item.available === 'no'),

    /**
     * @returns A map containing all selected options, indexed by item id.
     */
    selectedOptionsByItemId: (
      state,
    ):
      | Record<string, Array<{ choice: ShippingChoice } & ShippingOption>>
      | Record<string, never> =>
      state.items.reduce(
        (acc, item) => ({
          ...acc,
          [item.listingId]: item.options
            .map((option) => ({
              option,
              choice: option.choices.find(({ selected }) => selected),
            }))
            .filter(
              // Ensure choice was found
              ({ choice }) => !isEmpty(choice),
            ),
        }),
        {},
      ),
    priceAfterDiscount(): MonetaryAmount {
      const { isApplied, getDeductedPrice } = useDiscountStore()

      return isApplied ? getDeductedPrice : this.price
    },
    /**
     * @returns {boolean} Returns true if the current checkout state is shippable.
     * For regular shipping:
     * - shipping address is valid (see isShippingAddressComplete in the address store)
     * - country is available for shipping (shippableCountries)
     * For collection point shipping:
     * - customer details are valid (see isCollectionPointAddressComplete in the address store)
     * - note: no check is performed for the selected collection point, that is
     *         a separate check, which is used for a different redirection (see
     *         isSelectedCollectionPointMissing).
     */
    isShippable(): boolean {
      const {
        hasCollectionPoint,
        isBillingAddressComplete,
        isCollectionPointAddressComplete,
        isShippingAddressComplete,
        shipping,
        shippableCountries,
      } = useAddressStore()

      const isShippable = hasCollectionPoint
        ? isCollectionPointAddressComplete
        : isShippingAddressComplete &&
          shippableCountries.some(
            ({ countryCode }) => countryCode === shipping.country,
          )

      return isShippable && isBillingAddressComplete
    },
    availableItemsById(): { [key: string]: CartItem } {
      return this.availableItems.reduce(
        (acc, item) => ({ ...acc, [item.listingId]: item }),
        {},
      )
    },
    hasAvailableItems(): boolean {
      return !isEmpty(this.availableItems)
    },
    hasUnavailableItems(): boolean {
      return !isEmpty(this.unavailableItems)
    },
    availableItemsCount(): number {
      return this.availableItems.reduce((sum, item) => sum + item.quantity, 0)
    },
    /**
     * @returns insurance offers that have been selected
     */
    selectedInsuranceOffers(): InsuranceOfferWithSelectedState[] {
      return this.availableItems
        .map((item: CartItem) =>
          item.insuranceOffers?.find((offer) => offer.selected),
        )
        .filter(
          (offer): offer is InsuranceOfferWithSelectedState =>
            !!offer && !offer.defaultOffer,
        )
    },

    hasCollectionPointShipping(): boolean {
      return Object.values(this.selectedOptionsByItemId).some((options) =>
        options.some(
          ({ choice }: { choice: ShippingChoice }) => choice.isCollectionPoint,
        ),
      )
    },
    optionWithMissingCollectionPointSelected():
      | [
          string,
          ({
            choice: ShippingChoice
          } & ShippingOption)[],
        ]
      | undefined {
      return !this.hasSelectedCollectionPoint
        ? Object.entries(this.selectedOptionsByItemId).find(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([, option]) =>
              option.some(
                ({ choice }: { choice: ShippingChoice }) =>
                  choice.isCollectionPoint,
              ),
          )
        : undefined
    },

    selectedCollectionPoint(): CartCollectionPoint {
      return this.lastCollectionPoint?.selectedPoint
    },
    hasSelectedCollectionPoint(): boolean {
      return !isEmpty(this.selectedCollectionPoint)
    },
    isSelectedCollectionPointMissing(): boolean {
      return this.hasCollectionPointShipping && !this.hasSelectedCollectionPoint
    },
    isCollectionPointValid(): boolean {
      return (
        this.isSelectedCollectionPointMissing &&
        Boolean(this.optionWithMissingCollectionPointSelected)
      )
    },
    isItemAvailable() {
      return (listingId: string): boolean =>
        !!this.availableItemsById[listingId]
    },
    hasInsurance() {
      return this.selectedInsuranceOffers.length > 0
    },
    hasMonthlyInsurance(): boolean {
      return (
        this.hasInsurance &&
        this.selectedInsuranceOffers.some((offer) => offer.isMonthly)
      )
    },

    trackingData() {
      return (
        routeName: string,
      ): {
        products: EventData[]
        swap: boolean
        step: number
        pageType: string
      } => {
        const swapStore = useSwapStore()
        const { defaultCurrency } = useMarketplace().market

        return {
          products: this.items.map((item) => {
            const insurance = item.insuranceOffers?.find(
              (offer) => !offer.defaultOffer && offer.selected,
            )

            const deliveryOption = item.options.find(
              (option) => option.type === 'delivery',
            )
            const deliveryOptionChoices = deliveryOption?.choices || []

            return {
              available: item.available === 'yes',
              brand: item.brand ?? '',
              category: item.category ?? '',
              color: item.color ?? '',
              currency: defaultCurrency,
              id: item.productId ?? '',
              insurancePrice: insurance?.price.amount ?? '',
              insuranceTitle: insurance?.title ?? '',
              insurancePaymentType: getPaymentType(insurance),
              list: getProductInfos(item.akeneoId).source,
              listingId: item.listingId ?? '',
              merchantId: item.merchantPublicId ?? item.merchantId ?? '',
              model: item.model ?? '',
              name: item.title ?? '',
              specialOfferType: item.specialOfferType,
              price: item.price ?? '',
              quantity: item.quantity ?? '',
              quantityMax: item.quantityMax ?? '',
              shipper: [
                item.shippingSelected.shipperId ?? '',
                item.shippingSelected.shippingFeeDelay ?? '0',
                formatPrice({
                  amount: item.shippingSelected.shippingFeePrice,
                  currency: defaultCurrency,
                }),
              ].join(' - '),
              shippingDelay: item.shippingSelected.shippingFeeDelay ?? '',
              shippingPrice: item.shippingSelected.shippingFeePrice ?? '',
              shippingOptions: deliveryOptionChoices.map((choice) =>
                [
                  choice.shipperId ?? '',
                  choice.shippingDelay ?? '0',
                  formatPrice({
                    amount: choice.price,
                    currency: defaultCurrency,
                  }),
                ].join(' - '),
              ),
              uuid: item.akeneoId ?? '',
              variant: item.gradeId ?? '',
              warrantyDuration: item.warranties[0]?.delay ?? '',

              dealType: 'normal',
              isInsuranceEligible: !!item.insuranceOffers?.length || false,
              insuranceEligibleOffers:
                item.insuranceOffers &&
                item.insuranceOffers
                  .filter((offer) => !offer.defaultOffer)
                  .map((offer) => (offer.isMonthly ? 'monthly' : 'upfront'))
                  .join('-'),
              mobilePlanOfferSelected:
                item.mobilePlan?.selectedOffer.name ?? '',
            }
          }),
          swap: swapStore.hasOffer,
          step: CHECKOUT_STEP_IDS[routeName] ?? '',
          pageType: routeName ?? '',
        }
      }
    },
  },
  actions: {
    setPrice({
      totalListingsPrice,
      totalPrice,
      serviceFee,
      tax,
      totalDelivery,
      totalGrossPriceWithoutAddonService,
      totalGrossPriceWithoutBmFee,
      totalPriceIncludesTax,
      totalPriceAfterBuyback,
    }: CartSummary) {
      // Picking up currency from the total listing since we don't have a standalone currency prop
      const { currency } = totalListingsPrice

      this.price = { amount: totalPrice, currency }
      this.serviceFee = isEmpty(serviceFee) ? null : serviceFee
      this.tax = tax
      this.totalShippingPrice = totalDelivery
      this.totalGrossPriceWithoutAddonService =
        totalGrossPriceWithoutAddonService
      this.totalGrossPriceWithoutBmFee = totalGrossPriceWithoutBmFee
      this.totalListingsPrice = totalListingsPrice
      this.totalPriceIncludesTax = totalPriceIncludesTax
      this.totalPriceAfterBuyback = totalPriceAfterBuyback
    },
    setItems(items: CartItem[], monthlyFilterExperiment: string) {
      this.items = items.map((item) => {
        const filteredInsuranceOffers = item.insuranceOffers.filter((offer) =>
          filterWithMonthlyExperiment(monthlyFilterExperiment, offer),
        )

        const hasSelectableOffers = filteredInsuranceOffers.some(
          (offer) => !offer.defaultOffer,
        )

        const insuranceOffers = hasSelectableOffers
          ? filteredInsuranceOffers
          : []

        return {
          ...item,
          insuranceOffers,
        }
      })
    },
    setLastCollectionPoint(
      lastCollectionPoint?: LastCollectionPoint | Record<string, never>,
    ) {
      this.lastCollectionPoint = isEmpty(lastCollectionPoint)
        ? {}
        : lastCollectionPoint
    },
    setShowCatchupModal(showCatchupModal: boolean) {
      this.showCatchupModal = showCatchupModal
    },
    setCart(payload: CartResponse, monthlyFilterExperiment: string) {
      this.setPrice(payload.summary)
      this.setItems(payload.cartItems, monthlyFilterExperiment)
      this.setLastCollectionPoint(payload.lastCollectionPoint)
    },
    skipCatchupModal() {
      this.setShowCatchupModal(false)
    },
    async fetchCart() {
      const experiments = useExperiments()

      const newPriceGrid24 =
        experiments['experiment.serviceFeePriceGroup24'] === 'newPriceGrid24'

      const { setAddress } = useAddressStore()
      const { setSwap } = useSwapStore()
      const { setBasePrice } = useDiscountStore()
      const { setUserInformation } = useUserInformationStore()

      const cart = await $httpFetch(getCart, {
        queryParams: {
          newPriceGrid24,
          monthly_insurance_supported: true,
        },
      })

      if (!isEmpty(cart)) {
        this.setCart(cart, experiments['experiment.monthlyFilterExperiment'])

        setAddress(cart)
        setSwap(cart.swap)
        setUserInformation(cart.userInformation)
        setBasePrice({
          amount: cart?.summary.totalPrice ?? '',
          currency: this.price.currency,
        })
      }

      this.isCartLoaded = true
    },
  },
})
