<template>
  <p
    v-if="shouldDisplayGDPRNotice"
    class="text-static-default-low caption py-16 md:px-24"
  >
    <FormattedMessage :definition="translations[`page_${page}`]">
      <template #privacy>
        <RevLink
          class="text-inherit"
          target="_blank"
          :to="
            toNuxtLink({
              type: 'internal',
              name: CMS.LEGAL_PAGE,
              params: {
                pageName: LEGAL_PAGE_NAME.DATA_PROTECTION,
                locale: toBcp47Locale(locale),
              },
            })
          "
        >
          {{ i18n(translations.link) }}
        </RevLink>
      </template>
    </FormattedMessage>
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'
import { RevLink } from '@ds/components/Link'

import { CMS } from '~/scopes/cms/routes-names'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'

import translations from './GDPRNotice.translations'

defineProps<{ page: 'delivery' | 'payment' }>()

const i18n = useI18n()
const locale = useI18nLocale()

const { marketplace } = useMarketplace()

const shouldDisplayGDPRNotice = computed(() => {
  return marketplace.marketplaceCode === 'EU'
})
</script>
