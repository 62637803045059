import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { CHECKOUT } from '~/scopes/checkout/routes-names'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'

// As per nuxt documentation we should not return anything or return undefined if we do not want to alter the redirection.
// https://nuxt.com/docs/guide/directory-structure/middleware
// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware(async () => {
  const cartStore = useCartStore()
  const logger = useLogger()

  // We don't want to fire the request each time this middleware is instantiated.
  // If the cart was already fetched, there is no need to fetch it
  // again, and we should use the data saved in our store.
  if (!cartStore.isCartLoaded) {
    await cartStore.fetchCart()
  }

  if (!cartStore.hasAvailableItems) {
    logger.info('[CHECKOUT][MIDDLEWARE] No available items in the cart', {
      isCartLoaded: cartStore.isCartLoaded,
      cartItems: cartStore.items,
      lastCollectionPoint: cartStore.lastCollectionPoint,
      owners: ['bot-squad-checkout-front'],
    })

    return navigateTo({ name: CHECKOUT.CART })
  }
})
